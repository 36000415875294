<template>
    <div class="min-h-screen flex flex-col">
        <MenuApp class="grow-0"></MenuApp>

        <Home-pop-up></Home-pop-up>

        <div class="mb-auto grow w-full">
            <slot></slot>
        </div>

        <FooterApp class="grow-0"></FooterApp>

        <ChatFloating :slightly-up="true"></ChatFloating>
    </div>
</template>

<script>
import HomePopUp from "~/pages/components/HomePopUp.vue";

export default {
    name: "DefaultLayoutChatUp",
    components: {HomePopUp}
}
</script>

<style scoped lang="scss">

</style>
